import VueBaseController from '../../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/link_building/tasks/index.vue') }

  static values = {
    orderNumber: String,
    orderId: String
  }

  connect() {
    super.connect({
      orderNumber: this.orderNumberValue,
      orderId: this.orderIdValue
    })
  }
}

export class EditController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/link_building/tasks/edit.vue') }

  static values = {
    orderNumber: String,
    orderId: String,
    taskId: String
  }

  connect() {
    super.connect({
      orderNumber: this.orderNumberValue,
      orderId: this.orderIdValue,
      taskId: this.taskIdValue
    })
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/users/link_building/tasks/show.vue') }

  static values = {
    orderNumber: String,
    orderId: String,
    taskId: String
  }

  connect() {
    super.connect({
      orderNumber: this.orderNumberValue,
      orderId: this.orderIdValue,
      taskId: this.taskIdValue
    })
  }
}
